var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"nEUMLEGvYO9KrfmaR5ioP"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/restaurant";

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';
import { RewriteFrames } from '@sentry/integrations';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const config = getConfig();
    const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
    Sentry.init({
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
        enabled: true,
        environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
        ignoreErrors: [ 'ResizeObserver loop limit exceeded' ],
        integrations: [
            new RewriteFrames({
                iteratee: (frame) => {
                    if (frame.filename !== undefined) {
                        frame.filename = frame.filename.replace(distDir, 'app:///_next');
                    }
                    return frame;
                },
            }),
        ],
    });
}
